package com.picme

import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.lightningdb.*
import com.picme.components.RecyclableInfo
import com.picme.sdk2.UploadCondition
import com.picme.sdk2.UploadTime
import com.picme.sdk2.UploaderUserId
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.CollSubPage
import kotlinx.datetime.*
import kotlinx.datetime.TimeZone.Companion.UTC


object CollectionState {
    private val view = Property(CollSubPage.Main)

    val currView: Readable<CollSubPage> get() = view
    suspend fun setView(v: CollSubPage) {
        view set v
    }

    suspend fun toggleView(v: CollSubPage) {
        view set if (view() == v) CollSubPage.Main else v
    }

    val filterGuests = Property<List<CollectionUserData>>(emptyList())
    val filterDate = Property<FilterDate?>(null)

    val filtersOn: Readable<Boolean> = shared {
        filterGuests().isNotEmpty() || filterDate() != null
    }

    val selectItems = SelectItems()
    val selectTrash = SelectItems()
}

class SelectItems {
    private val items = Property<List<RecyclableInfo>?>(null)

    val isSelecting: Readable<Boolean> = shared { items() != null }
    val selected: Readable<List<RecyclableInfo>> = shared { items() ?: listOf() }

    suspend fun startSelecting() {
        items set listOf()
    }

    fun stopSelecting() {
        items.value = null
    }

    fun addItem(item: RecyclableInfo) {
        items.value = items.value?.plus(item)
    }

    fun removeItem(item: RecyclableInfo) {
        items.value = items.value?.minus(item)
    }
}

suspend fun CollectionState.filters(): UploadCondition {
    val guests = this@filters.filterGuests().map { it.userId.raw }
    val dateFilter = this@filters.filterDate()?.toTimeRange()

    if (guests.isEmpty() && dateFilter == null) {
        return Condition.Always()
    }
    val guestCondition: UploadCondition = condition {
        if (guests.isNotEmpty()) it.UploaderUserId inside guests
        else Condition.Always()
    }

    val dateCondition: UploadCondition = dateFilter ?: Condition.Always()
    return condition { guestCondition and dateCondition }
}

suspend fun CollectionState.clearFilters() {
    this.filterGuests set emptyList()
    this.filterDate set null
}


data class FilterDate(
    val type: DateOptions,
    val customStart: LocalDate? = null,
    val customEnd: LocalDate? = null,
)

enum class DateOptions(private val str: String) {
    Today("Today"),
    Yesterday("Yesterday"),
    ThisWeek("This Week"),
    Last7Days("Last 7 Days"),
    ThisMonth("This Month"),
    Last30Days("Last 30 Days"),
    Custom("Custom");

    override fun toString(): String {
        return str
    }
}

fun FilterDate.toTimeRange(): UploadCondition {
    val now = Clock.System.now()
    val today = now.toLocalDateTime(UTC).date
    return condition {
        when (this.type) {
            DateOptions.Today -> (it.UploadTime gt today.atStartOfDayIn(UTC).toString())

            DateOptions.Yesterday -> ((it.UploadTime gt today.minus(1, DateTimeUnit.DAY).atStartOfDayIn(UTC)
                .toString()) and (it.UploadTime lt today.toString()))

            DateOptions.ThisWeek -> it.UploadTime gt today.minus(today.dayOfWeek.ordinal.toLong(), DateTimeUnit.DAY)
                .atStartOfDayIn(UTC).toString()

            DateOptions.Last7Days -> {
                val sevenDaysAgo = today.minus(6, DateTimeUnit.DAY)
                sevenDaysAgo.atStartOfDayIn(UTC)
                return it.UploadTime gt sevenDaysAgo.atStartOfDayIn(UTC).toString()
            }

            DateOptions.ThisMonth -> {
                val today = now.toLocalDateTime(UTC)
                val d = LocalDateTime(
                    year = today.year,
                    month = today.month,
                    dayOfMonth = 1,
                    hour = 0,
                    minute = 0
                )
                return it.UploadTime gt d.toString()
            }

            DateOptions.Last30Days -> {
                val thirtyDaysAgo = today.minus(29, DateTimeUnit.DAY)
                return it.UploadTime gt thirtyDaysAgo.atStartOfDayIn(UTC).toString()
            }

            DateOptions.Custom -> (it.UploadTime gt this.customStart.toString()) and (it.UploadTime lt this.customEnd.toString())
        }
    }
}
