// Automatically generated based off Conditions.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.picme.sdk2

import com.lightningkite.lightningdb.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlin.reflect.KClass
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: 
fun prepareUploadConditionTypeFields() {
    val props: Array<SerializableProperty<UploadConditionType, *>> = arrayOf(UploadConditionType_UploaderUserId, UploadConditionType_UploadTime)
    UploadConditionType.serializer().properties { props }
}
val <K> DataClassPath<K, UploadConditionType>.UploaderUserId: DataClassPath<K, String> get() = this[UploadConditionType_UploaderUserId]
val <K> DataClassPath<K, UploadConditionType>.UploadTime: DataClassPath<K, String> get() = this[UploadConditionType_UploadTime]
inline val UploadConditionType.Companion.path: DataClassPath<UploadConditionType, UploadConditionType> get() = path<UploadConditionType>()


object UploadConditionType_UploaderUserId: SerializableProperty<UploadConditionType, String> {
    override val name: String by lazy { UploadConditionType.serializer().descriptor.getElementName(0) }
    override fun get(receiver: UploadConditionType): String = receiver.UploaderUserId
    override fun setCopy(receiver: UploadConditionType, value: String) = receiver.copy(UploaderUserId = value)
    override val serializer: KSerializer<String> by lazy { (UploadConditionType.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { UploadConditionType.serializer().descriptor.getElementAnnotations(0) }
}
object UploadConditionType_UploadTime: SerializableProperty<UploadConditionType, String> {
    override val name: String by lazy { UploadConditionType.serializer().descriptor.getElementName(1) }
    override fun get(receiver: UploadConditionType): String = receiver.UploadTime
    override fun setCopy(receiver: UploadConditionType, value: String) = receiver.copy(UploadTime = value)
    override val serializer: KSerializer<String> by lazy { (UploadConditionType.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { UploadConditionType.serializer().descriptor.getElementAnnotations(1) }
}
