package com.picme.sdk2


import com.lightningkite.lightningdb.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlin.reflect.KClass

@Serializable
@GenerateDataClassPaths
data class UploadConditionType(
    val UploaderUserId: String,
    val UploadTime: String
)

typealias UploadCondition = Condition<UploadConditionType>
