package com.picme.sdk2.generated.authentication

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class AuthenticationHandlerApiLive(val endpoints: AuthenticationHandlerEndpoints, val accessToken: suspend () -> String?): AuthenticationHandlerApi {
/** Continue a password reset operation (step two). **/
    override suspend fun continuePasswordReset(
        emailOrPhoneNumber: String,
        confirmationCode: String,
        newPassword: String,
    ): Unit = endpoints.continuePasswordReset(
        emailOrPhoneNumber = emailOrPhoneNumber,
        confirmationCode = confirmationCode,
        newPassword = newPassword,
        accessToken = accessToken,
    )
/** Continues the sign-up process for a user (step two). **/
    override suspend fun continueSignUpUser(
        emailOrPhoneNumber: String,
        verificationCode: String,
        setTosRead: Boolean,
        name: String?,
    ): Unit = endpoints.continueSignUpUser(
        emailOrPhoneNumber = emailOrPhoneNumber,
        verificationCode = verificationCode,
        setTosRead = setTosRead,
        name = name,
        accessToken = accessToken,
    )
/** Requests that an account verification message be resent to the specified email or phone number. **/
    override suspend fun resendAccountVerification(
        emailOrPhoneNumber: String,
    ): Unit = endpoints.resendAccountVerification(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )
/** Requests a password reset for a specified user. **/
    override suspend fun requestPasswordReset(
        emailOrPhoneNumber: String,
    ): Unit = endpoints.requestPasswordReset(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )

    override suspend fun signUpUser(
        emailOrPhoneNumber: String,
        password: String?,
        setTosRead: Boolean?,
        referrerUserId: UserId?,
        userName: String?,
    ): SignUpUserResponse = endpoints.signUpUser(
        emailOrPhoneNumber = emailOrPhoneNumber,
        password = password,
        setTosRead = setTosRead,
        referrerUserId = referrerUserId,
        userName = userName,
        accessToken = accessToken,
    )
/** Starts authenticating a user. **/
    override suspend fun startAuthentication(
        emailOrPhoneNumber: String,
        password: String,
    ): StartAuthenticationResponse = endpoints.startAuthentication(
        emailOrPhoneNumber = emailOrPhoneNumber,
        password = password,
        accessToken = accessToken,
    )
/** Starts authentication using Google OAuth credentials. **/
    override suspend fun startGoogleAuthentication(
        googleAuthorizationCode: String,
        setTosRead: Boolean,
    ): StartAuthenticationResponse = endpoints.startGoogleAuthentication(
        googleAuthorizationCode = googleAuthorizationCode,
        setTosRead = setTosRead,
        accessToken = accessToken,
    )
/** Checks a username to see if there is already a user using it. **/
    override suspend fun checkUsername(
        emailOrPhoneNumber: String,
    ): CheckUsernameResponse = endpoints.checkUsername(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )
/** Starts authentication using Apple OAuth credentials. **/
    override suspend fun startAppleAuthentication(
        appleAuthorizationCode: String,
        setTosRead: Boolean,
    ): StartAuthenticationResponse = endpoints.startAppleAuthentication(
        appleAuthorizationCode = appleAuthorizationCode,
        setTosRead = setTosRead,
        accessToken = accessToken,
    )
/** Gets the information needed to upload a new user profile picture.
            Note that this function does not actually upload the picture.  
            It just returns the URL to use to upload the picture, and removes any old profile picture in the process. **/
    override suspend fun getUserProfileUploadUrl(
        contentType: String,
    ): GetUserProfileUploadUrlResponse = endpoints.getUserProfileUploadUrl(
        contentType = contentType,
        accessToken = accessToken,
    )
/** Deletes the profile picture for the authenticated user. **/
    override suspend fun deleteUserProfilePicture(
    ): Unit = endpoints.deleteUserProfilePicture(
        accessToken = accessToken,
    )

    override suspend fun getUserProfileInfo(
        userId: UserId,
        collectionId: CollectionId?,
    ): GetUserProfileInfoResponse = endpoints.getUserProfileInfo(
        userId = userId,
        collectionId = collectionId,
        accessToken = accessToken,
    )
/** Gets the URLs needed to access and update client-defined user state data. **/
    override suspend fun getUserStateDataUrls(
    ): GetUserStateDataUrlsResponse = endpoints.getUserStateDataUrls(
        accessToken = accessToken,
    )
/** Sets user attributes. **/
    override suspend fun setUserAttributes(
        name: String?,
        setTosRead: Boolean,
    ): SetUserAttributesResponse = endpoints.setUserAttributes(
        name = name,
        setTosRead = setTosRead,
        accessToken = accessToken,
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    override suspend fun updateFirebaseCloudMessagingProjectConfig(
        config: FirebaseCloudMessagingProjectConfig,
    ): Unit = endpoints.updateFirebaseCloudMessagingProjectConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    override suspend fun getFirebaseCloudMessagingProjectConfig(
    ): GetFirebaseCloudMessagingProjectConfigResponse = endpoints.getFirebaseCloudMessagingProjectConfig(
        accessToken = accessToken,
    )
/** Changes the password for the authenticated user. **/
    override suspend fun changeUserPassword(
        oldPassword: String,
        newPassword: String,
    ): ChangeUserPasswordResponse = endpoints.changeUserPassword(
        oldPassword = oldPassword,
        newPassword = newPassword,
        accessToken = accessToken,
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    override suspend fun updateFirebaseCloudMessagingAppConfig(
        config: FirebaseCloudMessagingAppConfig,
    ): Unit = endpoints.updateFirebaseCloudMessagingAppConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    override suspend fun getFirebaseCloudMessagingAppConfig(
    ): GetFirebaseCloudMessagingAppConfigResponse = endpoints.getFirebaseCloudMessagingAppConfig(
        accessToken = accessToken,
    )
/** Gets the user information for the authenticated user. **/
    override suspend fun getMyUserInfo(
    ): GetMyUserInfoResponse = endpoints.getMyUserInfo(
        accessToken = accessToken,
    )
/** Refreshes user authentication using a refresh token. **/
    override suspend fun refreshUserAuthentication(
        refreshToken: String,
    ): RefreshUserAuthenticationResponse = endpoints.refreshUserAuthentication(
        refreshToken = refreshToken,
        accessToken = accessToken,
    )
/** Updates the Google OAuth configuration. **/
    override suspend fun updateGoogleAuthenticationConfig(
        config: GoogleAuthConfig,
    ): Unit = endpoints.updateGoogleAuthenticationConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Google OAuth configuration needed by the client.
            If this function returns a code of "NotFound", the client should assume that Google authentication is not available and should not display the option. **/
    override suspend fun getGoogleAuthenticationConfig(
    ): GetGoogleAuthenticationConfigResponse = endpoints.getGoogleAuthenticationConfig(
        accessToken = accessToken,
    )
/** Creates a Invite code object for the calling user to refer new users to PicMe.
            The Invite code will be owned by the calling user and will be hierarchically linked to the specified user.
            The link type for the activated link is <see cref="P:SimpleDatabase.AuthLinkRelationshipTypes.ReferralLinkType" /> with a link relationship of type <see cref="T:SimpleDatabase.ReferralType" />. **/
    override suspend fun createReferralInviteCode(
        name: String,
        clientInformation: String,
    ): CreateInviteCodeResponse = endpoints.createReferralInviteCode(
        name = name,
        clientInformation = clientInformation,
        accessToken = accessToken,
    )
/** Deletes the authenticated user account and everything it owns. **/
    override suspend fun deleteUser(
    ): DeleteUserResponse = endpoints.deleteUser(
        accessToken = accessToken,
    )
/** Updates the Apple OAuth configuration. **/
    override suspend fun updateAppleAuthenticationConfig(
        config: AppleAuthConfig,
    ): Unit = endpoints.updateAppleAuthenticationConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Apple OAuth configuration needed by the client.
            If this function returns a code of "NotFound", the client should assume that Apple authentication is not available and should not display the option. **/
    override suspend fun getAppleAuthenticationConfig(
    ): GetAppleAuthenticationConfigResponse = endpoints.getAppleAuthenticationConfig(
        accessToken = accessToken,
    )
/** Merges the currently-authenticated guest account into the specified full account, returning new tokens for the full account.
            The guest account's data will be removed, so the tokens used to make this call will not be valid after a successful call to this API. **/
    override suspend fun mergeIntoAccount(
        targetAccountEmailOrPhoneNumber: String,
        targetAccountPassword: String,
    ): MergeIntoAccountResponse = endpoints.mergeIntoAccount(
        targetAccountEmailOrPhoneNumber = targetAccountEmailOrPhoneNumber,
        targetAccountPassword = targetAccountPassword,
        accessToken = accessToken,
    )
/** Lists users referred by the currently-authenticated user. **/
    override suspend fun listMyReferrals(
    ): ListReferralsResponse = endpoints.listMyReferrals(
        accessToken = accessToken,
    )
/** Checks to see if a user is authenticated and if they are, gets information about the user. **/
    override suspend fun checkUserAuthentication(
    ): CheckUserAuthenticationResponse = endpoints.checkUserAuthentication(
        accessToken = accessToken,
    )
/** Invalidates any tokens for the authenticated user. **/
    override suspend fun invalidateUserTokens(
    ): Unit = endpoints.invalidateUserTokens(
        accessToken = accessToken,
    )
/** Checks for ownership and rights on the specified record. **/
    override suspend fun getRightsOn(
        recordGlobalId: RecordGlobalId,
    ): GetRightsOnResponse = endpoints.getRightsOn(
        recordGlobalId = recordGlobalId,
        accessToken = accessToken,
    )
/** Authenticates as a guest (creates a new guest account). **/
    override suspend fun authenticateAsGuest(
        setTosRead: Boolean,
        userName: String?,
    ): AuthenticateAsGuestResponse = endpoints.authenticateAsGuest(
        setTosRead = setTosRead,
        userName = userName,
        accessToken = accessToken,
    )
}
