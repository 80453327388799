package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.setStatusBarColor
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.picme.*
import com.picme.actuals.disableContextMenu
import kotlin.math.round


data class RecyclableInfo(
    val id: String,
    val thumbnail: ImageSource,
    val isAd: Boolean,
)

fun ViewWriter.imageRecyclerView(
    colImages: Readable<List<RecyclableInfo>>,
    selectedImages: SelectItems,
    setup: RecyclerView.() -> Unit,
    navToImage: (suspend (image: Readable<RecyclableInfo>) -> Page)? = null
) {
    expanding - recyclerView {
        new.log = null
        new.outerStack.exists = false
        new.outerStack::exists { colImages().isNotEmpty() }
        new.cells.spacing = 2.dp
        new.overdraw = 200.0
        reactiveSuspending {
            new.placer = RecyclerViewPlacerVerticalTrueGrid(round(AppState.windowInfo().width.px / (if (getGridSize() == GridSize.Large) 220 else 110).dp.px).toInt())
        }
        new.rendererSet = object: RecyclerViewRendererSet<RecyclableInfo, String> {
            override fun id(item: RecyclableInfo): String = item.id
            val main = object: RecyclerViewRenderer<RecyclableInfo> {
                override fun render(
                    viewWriter: ViewWriter,
                    data: Readable<RecyclableInfo>,
                    index: Readable<Int>
                ): ViewModifiable = with(viewWriter) {
                    val img = data
                    unpadded - card - button {
                        spacing = 0.rem
                        stack {
                            sizeConstraints(aspectRatio = 1 to 1) - tweakTheme { it.copy(cornerRadii = CornerRadii.Constant(0.dp)) } - image {
//                        useLoadingSpinners = false
                                // TODO: Debug why this is required on iOS
                                // Otherwise images display at about a third of the expected size. Is it an image caching thing?
                                // Is the thumbnail really just at a resolution that naturally appears small on high-res iOS screens?
                                naturalSize = true
                                disableContextMenu()
                                ::source { img().thumbnail }; scaleType = ImageScaleType.Crop
                            }
                            gravity(Align.Start, Align.Start) - sizeConstraints(
                                width = 2.rem,
                                height = 2.rem
                            ) - unpadded - image {
                                ::exists { selectedImages.selected().contains(img()) }
                                source = Resources.checkCircleSelected
                            }
                        }


                        onClick {
                            if (selectedImages.isSelecting()) {
                                if (selectedImages.selected().contains(img())) {
                                    selectedImages.removeItem(img())
                                } else {
                                    selectedImages.addItem(img())
                                }
                            } else {
                                setStatusBarColor(Color.picmeAuthGrey)
                                navToImage?.let { dialogScreenNavigator.navigate(it(img)) }
                            }
                        }
                    }
                }
            }
            override fun renderer(item: RecyclableInfo): RecyclerViewRenderer<RecyclableInfo> = main
        }
        reactive {
            val d = colImages()
            new.data = object: RecyclerViewData<RecyclableInfo, String> {
                override val range: IntRange get() = d.indices
                override fun get(index: Int): RecyclableInfo = d[index]
            }
        }
        setup()
    }
}
