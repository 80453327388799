package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.NoCornerRadiusNoOutlineNoBack
import com.picme.darkPurple
import com.picme.menuIconBg
import com.picme.separatorColor
import kotlin.properties.ReadWriteProperty

enum class SelectedTab { First, Second }

fun ViewWriter.tabSelect(whichOne: ImmediateWritable<SelectedTab>, first: String, second: String) {

    col {
        spacing = 0.dp
        themeChoice = NoCornerRadiusNoOutlineNoBack
        row {
            spacing = 0.dp
            tabItem(first, shared { whichOne() == SelectedTab.First }) { whichOne set SelectedTab.First }
            tabItem(second, shared { whichOne() == SelectedTab.Second }) { whichOne set SelectedTab.Second }
        }
        unpadded - row {
            spacing = 0.dp
            unpadded - expanding - sizeConstraints(height = 2.dp)- stack {
                dynamicTheme {
                    val bgColor = if (whichOne() == SelectedTab.First) Color.darkPurple else Color.separatorColor
                    ThemeDerivation { it.copy(background = bgColor).withBack }
                }
            }

            unpadded - expanding - sizeConstraints(height = 2.dp) - stack {
                dynamicTheme {
                    val bgColor = if (whichOne() == SelectedTab.Second) Color.darkPurple else Color.separatorColor
                    ThemeDerivation { it.copy(background = bgColor).withBack }
                }
            }
        }
    }
}

fun ViewWriter.tabItem(label: String, selected: Readable<Boolean>, onClick: suspend () -> Unit) {
    expanding - col {
        spacing = 0.dp
        row {
            expanding - ThemeDerivation {
                val c = CornerRadii.Constant(2.rem)
                val none = CornerRadii.Constant(0.rem)
                it.copy(cornerRadii = CornerRadii.PerCorner(c, c, none, none)).withoutBack
            }.onNext - button {
                dynamicTheme {
                    if (selected()) {
                        ThemeDerivation {
                            it.copy(background = Color.menuIconBg, foreground = Color.darkPurple).withBack
                        }
                    } else null
                }
                centered - bold - text(label)
                onClick {
                    onClick()
                }
            }
        }
    }
}