package com.picme.sdk2.generated.ad

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object AdHandlerEndpointsSerializer : KSerializer<AdHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("AdHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: AdHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): AdHandlerEndpoints {
        val string = decoder.decodeString()
        return AdHandlerEndpoints(string)
    }
}


@Serializable(with = AdHandlerEndpointsSerializer::class)
data class AdHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Records an ad impression (an ad that was seen for a certain amount of time in a certain context by an end user). **/
    suspend fun recordImpression(
        adId: String,
        collectionId: String?,
        contextId: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/impression",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "adId" to adId.toParamString(),
            "collectionId" to collectionId.toParamString(),
            "contextId" to contextId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists possible ads to use for future ad impressions. **/
    suspend fun listAds(
        collectionId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): ListAdsResponse = fetch(
        url = "$url/ads",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun adServe(
        collectionId: String?,
        adSize: AdSize?,
        contextId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/ad",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "adSize" to adSize.toParamString(),
            "contextId" to contextId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )
}

