package com.picme.sdk2.generated.ad

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class AdHandlerApiLive(val endpoints: AdHandlerEndpoints, val accessToken: suspend () -> String?): AdHandlerApi {
/** Records an ad impression (an ad that was seen for a certain amount of time in a certain context by an end user). **/
    override suspend fun recordImpression(
        adId: String,
        collectionId: String?,
        contextId: String?,
    ): Unit = endpoints.recordImpression(
        adId = adId,
        collectionId = collectionId,
        contextId = contextId,
        accessToken = accessToken,
    )
/** Lists possible ads to use for future ad impressions. **/
    override suspend fun listAds(
        collectionId: String?,
        unitTest: Boolean,
    ): ListAdsResponse = endpoints.listAds(
        collectionId = collectionId,
        unitTest = unitTest,
        accessToken = accessToken,
    )

    override suspend fun adServe(
        collectionId: String?,
        adSize: AdSize?,
        contextId: String?,
        unitTest: Boolean,
    ): RedirectResponse = endpoints.adServe(
        collectionId = collectionId,
        adSize = adSize,
        contextId = contextId,
        unitTest = unitTest,
        accessToken = accessToken,
    )
}
