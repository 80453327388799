package com.picme.components

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.icon
import com.picme.Resources
import com.picme.actuals.AdCache
import com.picme.actuals.advertisement
import com.picme.sdk2.generated.AdSize
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


fun ViewWriter.viewPagerWithAds(
    imagesWithAds: Readable<MutableList<ImageDisplayInfo>>,
    imageId: Property<RecyclableInfo>
) {
    unpadded - weight(1F) - viewPager {
        spacing = 0.rem
        val currImage = shared { imagesWithAds().first { it.id == imageId().id } }
        children(imagesWithAds) { imageDisplayInfo ->
            unpadded - stack {
                spacing = 0.25.rem
                zoomableImage {
//                    useLoadingSpinners = false
                    ::exists { imageDisplayInfo().originalVideo() == null && !imageDisplayInfo().isAd }
                    reactiveScope {
                        source = imageDisplayInfo().thumbnail
                        imageDisplayInfo().originalImage()?.let { source = it }
                    }
                    scaleType = ImageScaleType.Fit
                }
                video {
                    ::exists { imageDisplayInfo().originalVideo() != null && !imageDisplayInfo().isAd }
                    ::source { imageDisplayInfo().originalVideo() }
                    playing bind shared { currImage() == imageDisplayInfo() }.withWrite {  }
                    showControls = true
                }
                expanding - advertisement {
                    ::exists { imageDisplayInfo().isAd }
                    fullSizeAd = true
                    adUnitId = when (Platform.current) {
//                                Platform.Android -> "ca-app-pub-6829519054466679/5376859530"
                        Platform.Android -> "ca-app-pub-3940256099942544/6300978111" // test adUnitId
                        Platform.iOS -> "ca-app-pub-6829519054466679/9487311157"
                        else -> "ca-app-pub-3940256099942544/2435281174" // Test adUnitId
                    }
                    adImgSrc = Resources.picMePaigeAdVertical

                    launch {
                        if (Platform.current == Platform.Web) {
                            while (true) {
                                if (imageDisplayInfo().isAd && imageDisplayInfo().id == currImage().id) {
                                    adImgSrc = AdCache.getAd(AdSize.Large, "view-pager")
                                }
                                delay(3_000)
                            }
                        }
                    }
                }
            }
        }
        var lastIndex = 0
        reactive {
            lastIndex = index()
        }
        index bind shared {
            val targetId = imageId().id
            println("Target ID: $targetId")
            val newIndex = imagesWithAds().indexOfFirst { it.id == targetId }
            if (newIndex == -1) {
                println("WARN: Not found")
                if (imagesWithAds().size > lastIndex && lastIndex != 0) {
                    lastIndex - 2
                } else 0
            } else {
                newIndex
            }.also { println("Index is set to ${it}") }
        }.withWrite {
            imageId.value = imagesWithAds()[it].let {
                RecyclableInfo(it.id, it.thumbnail, it.isAd)
            }
        }
    }
}


data class ImageDisplayInfo(
    val id: String,
    val thumbnail: ImageSource,
    val originalImage: Readable<ImageSource?> = Constant(null),
    val isAd: Boolean = false,
    val originalVideo: Readable<VideoSource?> = Constant(null),
)
