package com.picme.sso

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.CornerRadii
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.image
import com.lightningkite.kiteui.views.direct.onClick
import com.picme.Resources
import com.picme.sdk2.unauthApi
import com.picme.views.devSettings
import com.picme.views.frontendUrl

fun ViewWriter.startSSO() {
    col {
        ::exists { devSettings() }
        button {
            themeChoice += ThemeDerivation {
                it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack
            }
            spacing = 0.dp
            image { source = Resources.sSOLoginGoogle }
            onClick {
                val clientId = unauthApi().authenticationHandler.getGoogleAuthenticationConfig()
                val redirectUrl = "${frontendUrl()}/sso-google"
                val url =
                    "https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId.googleClientId}&response_type=code&scope=openid%20email&redirect_uri=$redirectUrl"
                ExternalServices.openTab(url)
            }
        }
    }
}
