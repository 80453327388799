package com.picme.sdk2.caching

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.picme.sdk2.UploadCondition
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.sdk2.generated.collection2.*


interface CollectionHandler2ApiCacheable : CollectionHandler2Api {

    /** Gets both full metadata and a URI for the "details" version of the upload.
    If the upload has been deleted, this function will throw an exception. **/
    fun getUploadLive(
        collectionId: CollectionId,
        uploadId: UploadId,
    ): Readable<GetUploadResponse2>

    /** Gets both full metadata and a URI for the "details" version of a deleted upload.
    If the upload has not been deleted, this function will throw an exception.
    Requires <see cref="F:PicMeAuth.CollectionRights.View" /> and <see cref="F:PicMeAuth.CollectionRights.Delete" /> rights on the collection. **/
    fun getDeletedUploadLive(
        collectionId: CollectionId,
        uploadId: UploadId,
    ): Readable<GetUploadResponse2>

    /** Lists deleted uploads in a collection. **/
    fun listDeletedUploadsLive(
        collectionId: CollectionId,
    ): Paged<ListedUpload>

    /** Lists the uploads in a collection. **/
    fun listUploadsLive(
        collectionId: CollectionId,
        filters: UploadCondition
    ): Paged<ListedUpload>

    /** Gets information about the specified collection. **/
    fun getCollectionLive(
        collectionId: CollectionId,
    ): Readable<ListedCollection>

    suspend fun forceRefresh()

    /** null **/
    fun listCollectionsLive(
    ): Paged<ListedCollection>

    suspend fun putCollectionCoverPhoto(
        collectionId: CollectionId,
        body: RequestBody,
        tempUri: String?,
        onProgress: (Double) -> Unit
    )

    suspend fun createUpload(
        collectionId: CollectionId,
        anonymous: Boolean = false,
        caption: String? = null,
        data: RequestBody,
        hashCode: String
    ): ContinueUpload
}

interface ContinueUpload {
    suspend operator fun invoke(
        onProgress: (Double) -> Unit
    )
}

interface Paged<T> {
    val all: Readable<List<T>>
    val done: Readable<Boolean>
    val pullingMore: Readable<Boolean>
    var requireIndexLoaded: Int
}
//
//abstract class PagedHelper<T> : Paged<T> {
//    data class Response<T>(val next: String? = null, val list: List<T>)
//
//    abstract suspend fun next(token: String? = null): Response<T>
//
//    sealed interface State {
//        data object Loading : State
//        data class HasNextPage(val token: String) : State
//        data object Done : State
//    }
//
//    private var state = Property<State>(State.Loading)
//    private val data: LateInitProperty<List<T>> = LateInitProperty()
//    override val pullingMore: Readable<Boolean>
//        get() = loading
//    override val all: Readable<List<T>>
//        get() = data
//    override val done: Readable<Boolean>
//        get() = shared { state() == State.Done }
//    private var loading = Property(false)
//    override var requireIndexLoaded: Int = 0
//        set(value) {
//            field = value
//            startPullingIfNeeded()
//        }
//
//    private fun startPullingIfNeeded() {
//        if (!loading.value && data.value.lastIndex < requireIndexLoaded) {
//            pull()
//        }
//    }
//
//    private fun pull() {
//        when (val v = state.value) {
//            State.Done -> {}
//            else -> {
//                loading.value = true
//                launchGlobal {
//                    try {
//                        val result = next((v as? State.HasNextPage)?.token)
//                        state.value = result.next?.let { State.HasNextPage(it) } ?: State.Done
//                        if (data.state.ready) data.value += result.list
//                        else data.value = result.list
//                        if (data.value.lastIndex < requireIndexLoaded) pull()
//                    } finally {
//                        loading.value = false
//                    }
//                }
//            }
//        }
//    }
//
//    init {
////        println("Initializing PagedHelper")
//        pull()
//    }
//}
//
//class CollectionHandler2ApiCacheableLive(val basedOn: CollectionHandler2ApiLive) : CollectionHandler2ApiCacheable,
//    CollectionHandler2Api by basedOn {
//
//    override fun getUploadLive(collectionId: CollectionId, uploadId: UploadId): Readable<GetUploadResponse2> =
//        shared { basedOn.getUpload(collectionId, uploadId) }
//
//    override fun getDeletedUploadLive(collectionId: CollectionId, uploadId: UploadId): Readable<GetUploadResponse2> =
//        shared {
//            basedOn.getDeletedUpload(collectionId, uploadId)
//                .let { GetUploadResponse2(it.upload, it.getThumbnailUri, it.getDetailsUri) }
//        }
//
//    override fun listDeletedUploadsLive(collectionId: CollectionId): Paged<ListedUpload> =
//        object : PagedHelper<ListedUpload>() {
//            override suspend fun next(token: String?): Response<ListedUpload> =
//                basedOn.listDeletedUploads(collectionId, token, 100).let {
//                    Response(it.continuation, it.uploads)
//                }
//        }
//
//    override fun listUploadsLive(collectionId: CollectionId): Paged<ListedUpload> =
//        object : PagedHelper<ListedUpload>() {
//            override suspend fun next(token: String?): Response<ListedUpload> =
//                basedOn.listUploads(collectionId, token ?: "", 100).let {
//                    Response(it.continuation, it.uploads)
//                }
//        }
//
//    override fun getCollectionLive(collectionId: CollectionId): Readable<ListedCollection> = shared {
//        basedOn.getCollection(collectionId).let {
//            ListedCollection(
//                it.collection,
//                getCoverPhotoUri = it.getCoverPhotoUri,
//                userRights = Rights.fromRights(setOf(RightsEnum.Everything))
//            )
//        }
//    }
//
//    override fun listCollectionsLive(): Paged<ListedCollection> = object : PagedHelper<ListedCollection>() {
//        override suspend fun next(token: String?): Response<ListedCollection> =
//            // TODO() check to see if forUserId needs to be passed in
//            basedOn.listCollections(100, token, forUserId = null).let {
//                println("Got ${it.collections.size} collections")
//                Response(it.continuation, it.collections)
//            }
//    }
//
//    private val uploadFile: suspend (String, RequestBody, onProgress: (Double) -> Unit) -> Unit =
//        { uri, body, onProgress ->
//            fetch(uri, HttpMethod.PUT, body = body, onUploadProgress = { a, b ->
//                onProgress(a.toDouble() / b)
//            })
//        }
//
//    override suspend fun putCollectionCoverPhoto(
//        collectionId: CollectionId,
//        body: RequestBody,
//        onProgress: (Double) -> Unit
//    ) {
////        val t = basedOn.getCollectionModificationStamp(collectionId)
//        val r = basedOn.putCollectionCoverPhoto(collectionId, body.type)
//        uploadFile(r.putCoverPhotoUri, body, onProgress)
//    }
//
//    override suspend fun createUpload(
//        collectionId: CollectionId,
//        anonymous: Boolean,
//        caption: String?,
//        data: RequestBody
//    ): ContinueUpload {
//        val r = basedOn.createUpload(
//            collectionId = collectionId,
//            body = CreateUploadBody(
//                filename = if (data is RequestBodyFile) data.content.fileName() else "file",
//                contentType = MimeType(data.type),
//                caption = caption,
//                anonymous = anonymous,
//                identifiedHash = data.hashCode().toString(),
//            ),
//            allowDuplicates = true
//        )
//        when (data) {
//            is RequestBodyBlob -> uploadToLocal[r.upload.uploadId] = ImageRaw(data.content)
//            is RequestBodyFile -> uploadToLocal[r.upload.uploadId] = ImageLocal(data.content)
//            is RequestBodyText -> {}
//        }
//        return object : ContinueUpload {
//            override suspend fun invoke(onProgress: (Double) -> Unit) {
//                uploadFile(r.putOriginalUploadUri, data, onProgress)
//            }
//        }
//    }
//
//}