package com.picme.components

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.CollSubPage
import com.picme.views.imageSelectedCounter

fun ViewWriter.collectionButtonsTop() = stack {
    spacing = 0.rem
    val collection = shared { currentCollection() ?: PCollection() }
    val weAreOwner = sharedSuspending { currentCollection()?.let { ownsPCollection(it) } ?: false }
    val isSelecting = sharedSuspending {
        CollectionState.selectItems.isSelecting()
    }
    ifElse(
        condition = { isSelecting() },
        ifTrue = {
            bar - row {
                spacing = 0.rem
                selectRow(collection, weAreOwner)
            }
        },
        ifFalse = {
            bar - unpadded - row {
                spacing = 0.5.rem
                iconButtonTheme - button {
                    smallIcon(PIcon.select)
                    onClick {
                        CollectionState.selectItems.startSelecting()
                        CollectionState.setView(CollSubPage.Main)
                    }
                }
                iconButtonTheme - button {
                    ::exists { weAreOwner() }
                    smallIcon(PIcon.qrcode)
                    onClick {
                        CollectionState.toggleView(CollSubPage.Invites)
                    }
                    dynamicTheme {
                        if (CollectionState.currView() == CollSubPage.Invites) {
                            ThemeDerivation { theme ->
                                theme.copy(background = Color.menuIconBg).withBack
                            }
                        } else null
                    }
                }
                if (Platform.current == Platform.Android || Platform.current == Platform.iOS) {
                    addImageActions(PopoverPreferredDirection.belowCenter)
                } else {
                    stack {
                        centered - iconButtonTheme - ThemeDerivation {
                            it.copy(cornerRadii = CornerRadii.Constant(10.dp)).withBack
                        }.onNext - button {
                            spacing = 5.dp
                            smallIcon(PIcon.addWithCorners, "add image")
                            onClick {
                                CollectionState.setView(CollSubPage.Main)
                                uploadExternalFiles(collId = collection().collectionId)
                            }
                        }
                    }
                }
                iconButtonTheme - button {
                    ::exists { weAreOwner() }
                    smallIcon(PIcon.guests, "guests")
                    onClick {
                        CollectionState.toggleView(CollSubPage.Guests)
                    }
                    dynamicTheme {
                        if (CollectionState.currView() == CollSubPage.Guests) {
                            ThemeDerivation { theme ->
                                theme.copy(background = Color.menuIconBg).withBack
                            }
                        } else null
                    }
                }
                collectionActions(
                    collection,
                    PopoverPreferredDirection.belowLeft,
                    setView = { CollectionState.setView(it) }
                )
            }
        }
    )
}

fun ViewWriter.collectionButtonsBottom(collection: Readable<PCollection>, showOptions: Property<Boolean>) = stack {
    ::exists { !AppState.softInputOpen() }
    spacing = 0.rem
    val weAreOwner = sharedSuspending { ownsPCollection(collection()) }
    val collectionId = shared { currentCollection()?.collectionId ?: CollectionId("") }
    val isSelecting = sharedSuspending { CollectionState.selectItems.isSelecting() }
    ifElse(
        condition = { isSelecting() },
        ifTrue = {
            bar - row {
                spacing = 0.75.rem
                selectRow(collection, weAreOwner)
            }
        },
        ifFalse = {
            bar - row {
                spacing = 0.75.rem
                iconButtonTheme - button {
                    smallIcon(PIcon.select)
                    onClick {
                        CollectionState.selectItems.startSelecting()
                        CollectionState.setView(CollSubPage.Main)
                    }
                }
                expanding - space(0.0)
                stack {
                    ::exists { weAreOwner() }
                    iconButtonTheme - button {
                        smallIcon(PIcon.qrcode)
                        onClick {
                            CollectionState.toggleView(CollSubPage.Invites)
                        }
                    }
                }
                expanding - space(0.0)
                if (Platform.current == Platform.Android || Platform.current == Platform.iOS) {
                    addImageActions(PopoverPreferredDirection.aboveCenter)
                } else {
                    stack {
                        centered - iconButtonTheme - ThemeDerivation {
                            it.copy(cornerRadii = CornerRadii.Constant(10.dp)).withBack
                        }.onNext - button {
                            dynamicTheme {
                                if (CollectionState.currView() == CollSubPage.Guests) {
                                    ThemeDerivation {
                                        it.copy(background = Color.menuIconBg).withBack
                                    }
                                } else null
                            }
                            spacing = 5.dp
                            smallIcon(PIcon.addWithCorners, "add image")
                            onClick {
                                CollectionState.setView(CollSubPage.Main)
                                uploadExternalFiles(collId = collectionId())
                            }
                        }
                    }
                }
                expanding - space(0.0) {}
                iconButtonTheme - button {
                    ::exists { weAreOwner() }
                    smallIcon(PIcon.guests, "guests")
                    onClick {
                        CollectionState.setView(CollSubPage.Guests)
                    }
                }
                expanding - space(0.0)
                collectionActions(
                    collection,
                    PopoverPreferredDirection.aboveLeft,
                    setView = { CollectionState.setView(it) }
                )
            }
        }
    )
    atTop - separatorTheme - separator { }
}

fun ViewWriter.selectRow(collection: Readable<PCollection>, weAreOwner: Readable<Boolean>) {
    imageSelectedCounter(CollectionState.selectItems)
    expanding - space {}
    val imgsAreSelected = shared { CollectionState.selectItems.selected().isNotEmpty() }

    actionButtonNoText(PIcon.copy, enabled = imgsAreSelected) {
        openCopyMenu(CollectionState.selectItems.selected().map { UploadId(it.id) })
    }

    space {}
    space {}
    iconButtonTheme - button {
        smallIcon(PIcon.download)
        ::enabled{ imgsAreSelected() }
        onClick {
            val message = when (Platform.current) {
                Platform.Web -> "Selected items will be downloaded to your device as a zip file."
                Platform.iOS -> "Selected items will be saved to your Camera Roll."
                else -> "Selected items will be saved to your device."
            }
            showConfirmDialog("Confirm Download", message, "Download") {
                collectionDownload(
                    collection().collectionId,
                    CollectionState.selectItems.selected().map { UploadId(it.id) })
                CollectionState.selectItems.stopSelecting()
            }
        }
    }
    space {}
    actionButtonNoText(
        displayIcon = PIcon.trash,
        exists = sharedSuspending { weAreOwner() || session()?.isVerifiedAccount() == false },
        enabled = imgsAreSelected
    ) {
        showConfirmDialog(
            title = "Confirm Delete",
            content = "Selected items will be deleted from the collection.",
            confirmLabel = "Delete",
            onConfirm = {
                val selected = CollectionState.selectItems.selected().map { it.id }
                val images = collection().images().all().filter { it.uploadId.raw in selected }
                CollectionState.selectItems.stopSelecting()
                deleteImages(images)
            }
        )
    }
}

//fun ViewWriter.filterButton() {
//    centered - iconButtonTheme - stack {
//        spacing = 0.dp
//        dynamicTheme {
//            if (CollectionState.currView() == CollSubPage.Filters) {
//                ThemeDerivation { it.copy(background = Color.menuIconBg).withBack }
//            } else null
//        }
//    }
//}

