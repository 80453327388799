package com.picme

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsApps() { AppsModelsObject }
object AppsModelsObject {
    init {
        SerializationRegistry.master.registerApps()
        com.picme.sdk2.prepareUploadConditionTypeFields()
    }
}

fun SerializationRegistry.registerApps() {
    register(com.picme.InviteType.serializer())
    register(com.picme.ClientInfo.serializer())
    register(com.picme.GridSize.serializer())
    register(com.picme.QRScanEvent.serializer())
    register(com.picme.sdk2.UploadConditionType.serializer())
    register(com.picme.sdk2.Retainable.serializer(NothingSerializer()).descriptor.serialName) { com.picme.sdk2.Retainable.serializer(it[0]) }
    register(com.picme.sdk2.generated.ApiEndpoints.serializer())
    register(com.picme.sdk2.generated.ListAdsResponse.serializer())
    register(com.picme.sdk2.generated.ListedAd.serializer())
    register(com.picme.sdk2.generated.AdSize.serializer())
    register(com.picme.sdk2.generated.HttpRedirect.serializer())
    register(com.picme.sdk2.generated.UserId.serializer())
    register(com.picme.sdk2.generated.CollectionId.serializer())
    register(com.picme.sdk2.generated.LinkRelationshipType.serializer())
    register(com.picme.sdk2.generated.CreateInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.InviteCode.serializer())
    register(com.picme.sdk2.generated.LinkActivator.serializer())
    register(com.picme.sdk2.generated.LinkTemplate.serializer())
    register(com.picme.sdk2.generated.LinkConflictResolutionEnum.serializer())
    register(com.picme.sdk2.generated.LinkConflictResolution.serializer())
    register(com.picme.sdk2.generated.LinkRelationship.serializer())
    register(com.picme.sdk2.generated.InviteCodeId.serializer())
    register(com.picme.sdk2.generated.RecordGlobalId.serializer())
    register(com.picme.sdk2.generated.MimeType.serializer())
    register(com.picme.sdk2.generated.CreateQrCodeImageResponse.serializer())
    register(com.picme.sdk2.generated.ad.AdHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.authentication.AuthenticationHandlerEndpoints.serializer())
    register(com.picme.sdk2.generated.authentication.SignUpUserResponse.serializer())
    register(com.picme.sdk2.generated.authentication.StartAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.MoreAuthenticationRequired.serializer())
    register(com.picme.sdk2.generated.authentication.CollectNewPassword.serializer())
    register(com.picme.sdk2.generated.authentication.NewPasswordChallengeToken.serializer())
    register(com.picme.sdk2.generated.authentication.AuthenticationComplete.serializer())
    register(com.picme.sdk2.generated.authentication.UserData.serializer())
    register(com.picme.sdk2.generated.authentication.UserAuthenticated.serializer())
    register(com.picme.sdk2.generated.authentication.CheckUsernameResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserProfileUploadUrlResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserProfileInfoResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetUserStateDataUrlsResponse.serializer())
    register(com.picme.sdk2.generated.authentication.SetUserAttributesResponse.serializer())
    register(com.picme.sdk2.generated.authentication.FirebaseCloudMessagingProjectConfig.serializer())
    register(com.picme.sdk2.generated.authentication.GetFirebaseCloudMessagingProjectConfigResponse.serializer())
    register(com.picme.sdk2.generated.authentication.ChangeUserPasswordResponse.serializer())
    register(com.picme.sdk2.generated.authentication.FirebaseCloudMessagingAppConfig.serializer())
    register(com.picme.sdk2.generated.authentication.GetFirebaseCloudMessagingAppConfigResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetMyUserInfoResponse.serializer())
    register(com.picme.sdk2.generated.authentication.RefreshUserAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GoogleAuthConfig.serializer())
    register(com.picme.sdk2.generated.authentication.GetGoogleAuthenticationConfigResponse.serializer())
    register(com.picme.sdk2.generated.authentication.AuthLinkRelationshipTypes.serializer())
    register(com.picme.sdk2.generated.authentication.ReferralType.serializer())
    register(com.picme.sdk2.generated.authentication.DeleteUserResponse.serializer())
    register(com.picme.sdk2.generated.authentication.AppleAuthConfig.serializer())
    register(com.picme.sdk2.generated.authentication.GetAppleAuthenticationConfigResponse.serializer())
    register(com.picme.sdk2.generated.authentication.MergeIntoAccountResponse.serializer())
    register(com.picme.sdk2.generated.authentication.ListReferralsResponse.serializer())
    register(com.picme.sdk2.generated.authentication.Referral.serializer())
    register(com.picme.sdk2.generated.authentication.CheckUserAuthenticationResponse.serializer())
    register(com.picme.sdk2.generated.authentication.GetRightsOnResponse.serializer())
    register(com.picme.sdk2.generated.authentication.AuthenticateAsGuestResponse.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionHandler2Endpoints.serializer())
    register(com.picme.sdk2.generated.collection2.UploadId.serializer())
    register(com.picme.sdk2.generated.collection2.GetDeletedUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.Upload.serializer())
    register(com.picme.sdk2.generated.collection2.Text.serializer())
    register(com.picme.sdk2.generated.collection2.UploadState.serializer())
    register(com.picme.sdk2.generated.collection2.ListLinksToResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.Link.serializer())
    register(com.picme.sdk2.generated.collection2.FileSystemRelativeFilePath.serializer())
    register(com.picme.sdk2.generated.collection2.GetUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.UploaderInfo.serializer())
    register(com.picme.sdk2.generated.collection2.PatchUploadBody.serializer())
    register(com.picme.sdk2.generated.collection2.PatchUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CopyUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionModificationStampResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.PutCollectionCoverPhotoResponse.serializer())
    register(com.picme.sdk2.generated.collection2.ListDeletedUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListedUpload.serializer())
    register(com.picme.sdk2.generated.collection2.RestoreDeletedUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListUploadKeywordsResponse.serializer())
    register(com.picme.sdk2.generated.collection2.GetOrStartPartialZipOfOriginalsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetOrStartFullZipOfOriginalsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode.serializer())
    register(com.picme.sdk2.generated.collection2.CreateUploadBody.serializer())
    register(com.picme.sdk2.generated.collection2.CreateUploadResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.UploadQuery.serializer())
    register(com.picme.sdk2.generated.collection2.Int64Range.serializer())
    register(com.picme.sdk2.generated.collection2.DateTimeRange.serializer())
    register(com.picme.sdk2.generated.collection2.ListUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteAllUploadsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.RevokeRightsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.RightsEnum.serializer())
    register(com.picme.sdk2.generated.collection2.Rights.serializer())
    register(com.picme.sdk2.generated.collection2.ModifyRightsResponse.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionRightsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.GetCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.PCollection.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionPlan.serializer())
    register(com.picme.sdk2.generated.collection2.GenericIdentifier.serializer())
    register(com.picme.sdk2.generated.collection2.Tumbler.serializer())
    register(com.picme.sdk2.generated.collection2.PatchCollectionBody.serializer())
    register(com.picme.sdk2.generated.collection2.PatchCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.DeleteCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ActivateInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.collection2.ListActivatedUsersResponse.serializer())
    register(com.picme.sdk2.generated.collection2.ActivatedUserData.serializer())
    register(com.picme.sdk2.generated.collection2.ListUsersResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionUserData.serializer())
    register(com.picme.sdk2.generated.collection2.GetInviteCodeResponse.serializer())
    register(com.picme.sdk2.generated.collection2.PatchInviteCodeBody.serializer())
    register(com.picme.sdk2.generated.collection2.CreateCollectionBody.serializer())
    register(com.picme.sdk2.generated.collection2.CreateCollectionResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.CollectionQuery.serializer())
    register(com.picme.sdk2.generated.collection2.ListCollectionsResponse2.serializer())
    register(com.picme.sdk2.generated.collection2.ListedCollection.serializer())
    register(com.picme.sdk2.generated.collection2.CreateInviteCodeBody.serializer())
    register(com.picme.sdk2.generated.collection2.ListInviteCodesResponse.serializer())
    register(com.picme.sdk2.generated.collection2.UploadOriginalAvailability.serializer())
    register(com.picme.sdk2.BackendUrls.serializer())
    register(com.picme.sdk2.UploadImageInfo.serializer())
    register(com.picme.views.Environment.serializer())
    register(com.picme.views.LegacyAccept.serializer())
    register(com.picme.views.AcceptInvite.serializer())
}
